<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table>
                        <tr style="">
                            <td style="width: 80px;text-align: center;">
                                用户账号：
                            </td>
                            <td style="width: 28%;">
                                <el-input v-model="loginName" placeholder="请输入用户账号" size="small"
                                ></el-input>
                            </td>
                            <td style="width: 80px;text-align: center;">
                                用户名称：
                            </td>
                            <td style="width: 28%">
                                <el-input v-model="userName" placeholder="请输入用户名称" size="small"
                                ></el-input>
                            </td>
                            <td style="width: 300px; text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if="hasAuthority('sys_user_add')" @click="addRole">增加
                                </el-button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                border
                                class="table"
                                header-cell-class-name="table-header"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="排序" width="60" align="center"></el-table-column>
                            <el-table-column prop="userCode" label="账号" width="120" align="center"></el-table-column>
                            <el-table-column prop="userName" label="姓名" width="120" align="center"></el-table-column>
                            <el-table-column prop="userTel" label="联系方式" width="120" align="center"></el-table-column>
                            <el-table-column prop="roleName" label="角色" width="120" align="center"></el-table-column>
                            <el-table-column prop="depName" label="部门" width="120" align="center"></el-table-column>
                            <el-table-column prop="position" label="职位" width="120" align="center"></el-table-column>
                            <el-table-column prop="userEmail" label="邮件" width="200" align="center"></el-table-column>
                            <el-table-column prop="remark" label="描述" align="center"></el-table-column>
                            <el-table-column prop="id" label="操作" width="100" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('sys_user_update')"
                                           @click="editUser(scope.row)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('sys_user_delete')"
                                           @click="deleteUser(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog">
            <el-dialog
                    :title=title
                    :visible.sync="dialogVisible"
                    width="60%"
                    :show-close="false"
                    style="margin-top: -60px"
                    :close-on-click-modal="isClose"
                    center>
                <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px">
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="用户名称" prop="userName">
                                <el-input v-model="userForm.userName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="昵称" prop="shortName">
                                <el-input v-model="userForm.shortName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="账号" prop="userCode">
                                <el-input v-if="type == 'edit'" disabled v-model="userForm.userCode" readonly></el-input>
                                <el-input v-else v-model="userForm.userCode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="密码" prop="passWord">
                                <el-input v-model="userForm.passWord"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="联系方式" prop="userTel">
                                <el-input v-model.number="userForm.userTel" autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="角色" prop="roleId">
                                <el-select v-model="userForm.roleId" placeholder="请选择角色" style="width: 100%;">
                                    <el-option
                                            v-for="item in roles"
                                            :key="item.id"
                                            :label="item.roleName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="部门" prop="depId">
                                <el-select v-model="userForm.depId" placeholder="请选择部门" style="width: 100%;">
                                    <el-option
                                            v-for="item in departs"
                                            :key="item.id"
                                            :label="item.depName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="职位" prop="position">
                                <el-input v-model="userForm.position"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item label="邮件" prop="userEmail">
                                <el-input v-model="userForm.userEmail"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="描述" prop="remark">
                        <el-input type="textarea" :rows="3" v-model="userForm.remark"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('userForm')">取消</el-button>
                     <el-button type="primary" @click="saveUser('userForm',type)">保存</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "User",
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                lineHeight: '',
                padding: "6px 0 5px 0",
                roles: [],
                departs: [],
                isClose: false,
                type: '',
                userName: '',
                loginName: '',
                tableData: [],
                dialogVisible: false,
                title: '',
                multipleSelection: [],
                delList: [],
                // totalPage:0,
                totalPage: 30,
                currentPage: 1,
                option: {
                    pageIndex: 1,
                    pageSize: 16,
                },
                current: 1,
                userForm: {
                    userName: '',
                    shortName: '',
                    passWord: '',
                    userCode: '',
                    roleId: '',
                    position: '',
                    // depId:'',
                    userTel: '',
                    remark: '',
                    userEmail: ''
                },
                rules: {
                    userName: [
                        {required: true, message: '请输入用户名称', trigger: 'blur'},
                    ],
                    roleId: [
                        {required: true, message: '请输入用户角色', trigger: 'blur'},
                    ],
                    userCode: [
                        {required: true, message: '请输入登录名称', trigger: 'blur'},
                    ],
                    userTel: [
                        {required: true, message: '请输入联系方式', trigger: 'blur'},
                        {type: 'number', message: '电话号码必须是数字'}
                    ],
                    depId: [
                        {required: true, message: '请选择部门名称', trigger: 'blur'},
                    ]
                },
                indexPage: 1,
            }
        },
        methods: {
            chooseSelection(val) {
                this.multipleSelection = val
            },
            searchBtu() {
                this.pageChange(1)
            },
            reset() {
                this.userName = '';
                this.loginName = '';
                this.pageChange(1)
                // 重置的方法
            },
            addRole() {
                this.type = 'save'
                this.title = '新增用户信息';
                this.dialogVisible = true;
                this.userForm = {
                    id: null,
                    userName: '',
                    shortName: '',
                    passWord: '123456',
                    userCode: '',
                    roleId: '',
                    position: '',
                    depId: '',
                    userTel: '',
                    remark: '',
                    userEmail: ''
                }
                this.$api.getUserRole({id: null}).then(res => {
                    this.roles = res.data.roles;
                    this.departs = res.data.depList;
                })
            },
            editUser(row) {
                this.title = '编辑用户信息';
                this.type = 'edit'
                this.dialogVisible = true;
                row.userTel = row.userTel * 1
                this.$api.getUserRole({id: row.id}).then(res => {
                    this.roles = res.data.roles;
                    this.departs = res.data.depList;
                    var roleId = res.data.roleId;
                    this.userForm = {
                        id: res.data.id,
                        userName: res.data.userName,
                        shortName: res.data.shortName,
                        passWord: res.data.passWord,
                        userCode: res.data.userCode,
                        roleId: roleId,
                        position: res.data.position,
                        depId: res.data.depId,
                        userTel: res.data.userTel * 1,
                        remark: res.data.remark,
                        userEmail: res.data.userEmail
                    }
                })
            },
            deleteUser(id) {
                //删除的方法
                this.$confirm('你确定要删除用户信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteUser({id}).then(
                        res => {
                            if (res.code = 200) {
                                this.searchBtu();
                                this.$message.success(res.message);
                            } else {
                                this.$message.error(res.message);
                            }
                        }
                    )
                }).catch(() => {
                })
            },
            resetForm(formName) {
                this.dialogVisible = false;
                this.type = ''
                this.$refs[formName].resetFields();
            },
            saveUser(formName, type) {
                this.userForm.userTel = this.userForm.userTel * 1
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (type == 'save') {
                            this.$api.saveUser(this.userForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.dialogVisible = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateUser(this.userForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(this.currentPage);
                                    this.dialogVisible = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        return false;
                    }
                })
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.currentPage = option;
                this.$api.pageUser({
                    userCode: this.loginName,
                    userName: this.userName,
                    pageIndex: option,
                    pageSize: 16,
                }).then(res => {
                    this.tableData = res.data.content;
                    this.totalPage = res.data.totalElements;
                })
            },
            pageSizeChange(option) {
                this.$api.pageUser({
                    userCode: this.loginName,
                    userName: this.userName,
                    pageIndex: 1,
                    pageSize: option,
                }).then(res => {
                    this.tableData = res.data.content;
                    this.totalPage = res.data.totalElements;
                })
            },
        },
        created() {
            this.searchBtu()
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;

    }

    .table-header {
        height: 5%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;

    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        //border: 1px red solid;
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }


    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>
